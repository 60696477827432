import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3"
import Environment from "../../utils/Environment";
import { TomiStackContract } from "../../utils/contractHelpers";
// import { getFullDisplayMul } from "../../utils/formatBalance";
// import { earnAbi } from "../../utils/contractHelpers";

export const StakeTomiToken = () => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.StackContract;
    const contract = TomiStackContract(contractAddress, web3);
    const TomiStakeToken = useCallback(
        async (stackamount, update, account) => {
            // console.log("account111", account)
            // console.log("ddddd111uygu11",typeof update);
            try {
                // console.log("ddddddddddd",stackamount);
                let bal = web3.utils.toWei(stackamount.toString(), "ether");
                // let bal = getFullDisplayMul(stackamount)
                // console.log("ddddd11111", bal);
                const balance = await contract.methods.stakeForAPY(bal, update).send({ from: account })
                // console.log('555555555', balance);
                return balance
            } catch (error) {
                console.log('9999999', error)
                throw error;
            }
        }, []
    );
    return { TomiStakeToken: TomiStakeToken };
}
export default StakeTomiToken;