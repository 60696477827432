import React, { useState, useEffect } from "react";
import "./tokenstaking.scss";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { Slider } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetBalance } from "../../hooks/dataFetchers/getBalance";
import { GetTotalHolders } from "../../hooks/dataFetchers/getTvl";
import { ApproveAllow } from "../../hooks/dataSenders/approvedAllowence";
import { WidthrawRewards } from "../../hooks/dataSenders/WidthrawReward";
import { ClaimRewards } from "../../hooks/dataSenders/claimReward";
import { EarnApy } from "../../hooks/dataFetchers/earnApy";
import { TomiAllowance } from "../../hooks/dataFetchers/allowence";
import { GetPendingReward } from "../../hooks/dataFetchers/getPendingReward";
import moment from "moment";
import Loader from "../../hooks/loader";
import Environment from "../../utils/Environment";
import axios from "axios";
import { StakeTomiToken } from "../../hooks/dataSenders/stakeTomi";
import { Modal } from "react-bootstrap";
import useWeb3 from "../../hooks/useWeb3";
import { TomiStackContract } from "../../utils/contractHelpers";
import NavbarNew from "../landing/header/NavBarNew";
import Newbanner from "../landing/Newbanner/Newbanner";
import Power from "../landing/Power/Power";
import useAuth from "../../../src/hooks/useAuth";

// import { io } from "socket.io-client";
const Token_staking = () => {
  var currencyFormatter = require("currency-formatter");
  const web3 = useWeb3();
  const [addCls, setcls] = useState();
  const [update, setupdate] = useState(0);
  const [showsuccess, setShowsuccess] = useState(false);
  const handleClosesuccess = () => setShowsuccess(false);
  const handleShowsuccess = () => setShowsuccess(true);
  const [mainLoader, setMainLoader] = useState(false);
  const { account } = useWeb3React();
  const [balanceOf, setBalanceOf] = useState("");
  const [TPL, setTPLf] = useState("");
  const [approv, setApprov] = useState("");
  const [apy, setApy] = useState("");
  const [pendreward, setpendreward] = useState("");
  // console.log("pending reward",pendreward)
  const [activeDeposit, setActiveDeposit] = useState("");
  // console.log("asjdasdbajkdjaks", activeDeposit)
  const [allowance, setAllowance] = useState("");
  const [value, setValue] = React.useState(0);
  const [valueamount, setvalueamount] = useState();
  // console.log("allowence", allowance)
  const { GetBal } = GetBalance();
  const { GetHolder } = GetTotalHolders();
  const { ApproveTokenAllow } = ApproveAllow();
  const { AllowanceTomi } = TomiAllowance();
  const { apyAmount } = EarnApy();
  const { TomiStakeToken } = StakeTomiToken();
  const { PendingReward } = GetPendingReward();
  const { RewardWidraw } = WidthrawRewards();
  const { Rewardclaim } = ClaimRewards();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [limit] = useState(1);
  const [detailData, setDetailData] = useState({
    data: "",
    id: "",
  });

  // console.log("apyyyy", TPL)

  const ApyFun = async () => {
    let result = await apyAmount(update);
    setApy(result);
  };

  useEffect(() => {
    // if (account) {
    ApyFun(update);
    // }
  }, [update]);

  const indexsetfunction = (index) => {
    setcls(index);
  };

  const getBalance = async () => {
    let result = await GetBal(account);
    let result2 = await GetHolder();
    // console.log("resulttvl", result2)
    setBalanceOf(result);
  };

  const getTotalStakers = async () => {
    let result2 = await GetHolder();
    // console.log("resulttvl", result2)
    setTPLf(result2);
  };

  const ApproveFun = async () => {
    if (account) {
      try {
        // console.log("approve")
        if (account) {
          if (balanceOf > 0) {
            setMainLoader(true);
            let result = await ApproveTokenAllow();
            setApprov(result);
            StackingAllowenceCheck();
            setMainLoader(false);
          } else {
            toast.error("Tomi balance must be greater then 0", {
              position: "top-center",
              autoClose: 5000,
            });
          }
        } else {
          toast.error("Please connect your wallet first to approve", {
            position: "top-center",
            autoClose: 5000,
          });
        }
      } catch {
        setMainLoader(false);
      }
    } else {
      toast.error("Please Connect Your Wallet First", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const StackingAllowenceCheck = async () => {
    try {
      // setMainLoader(true);
      let result = await AllowanceTomi();
      setAllowance(result);
      // setMainLoader(false);
    } catch {
      setMainLoader(false);
    }
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    setvalueamount(parseInt((newValue / 100) * balanceOf));
  };

  const handleInputChange = (event) => {
    // if (account) {
    if (event.target.value != "") {
      // console.log("value calcutalte", account)
      setValue(parseFloat((event.target.value / balanceOf) * 100));
      setvalueamount(event.target.value);
    } else {
      setValue(0);
      setvalueamount();
    }
    // }
    // else {
    //     toast.error('Please connect your wallet first to calculate the APY', {
    //         position: 'top-center',
    //         autoClose: 5000,
    //     });
    //     event.target.value = null
    // }
  };

  const handleMaxBal = () => {
    setvalueamount(parseInt(balanceOf));
    if (balanceOf > 0) {
      setValue(Math.round(balanceOf && (balanceOf / balanceOf) * 100));
    }
  };
  // console.log("amount", (12 / 100 * valueamount) * 2 + parseFloat(valueamount), typeof valueamount)S

  const StakeFun = async () => {
    if (account) {
      try {
        if (
          valueamount <= "" ||
          valueamount === undefined ||
          valueamount <= 0
        ) {
          toast.error("Please enter tomi value to stake", {
            position: "top-center",
            autoClose: 5000,
          });
        } else if (valueamount > balanceOf) {
          toast.error("Selected value must be smaller then your tomi balance", {
            position: "top-center",
            autoClose: 5000,
          });
        } else if (allowance >= parseFloat(valueamount) === true) {
          setMainLoader(true);
          const contractAddress = Environment.StackContract;
          const contract = TomiStackContract(contractAddress, web3);
          try {
            // let bal = web3.utils.toWei(valueamount.toString(), "ether");
            // const gasPriceFromStation = await axios({
            //     method: "get",
            //     url: 'https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=YourApiKeyToken',
            //   });
            let gasPrice = await web3.eth.getGasPrice();
            let bal = web3.utils.toWei(valueamount.toString(), "ether");
            const gas = await contract.methods
              .stakeForAPY(bal, update)
              .estimateGas({ from: account, gasPrice });
            console.log("gaasssss", gasPrice, gas);
            // let gasgpricenew = gas + 1000000
            const balance = await contract.methods
              .stakeForAPY(bal, update)
              .send({ from: account, gas, gasPrice: gasPrice });
            // const balance = await contract.methods.stakeForAPY(bal, update).send({ from: account })
            if (balance) {
              setTimeout(() => {
                getBalance();
                getDepositData();
                StackingAllowenceCheck();
                setMainLoader(false);
                toast.success("Staked Successfully", {
                  position: "top-center",
                  autoClose: 5000,
                });
              }, 10000);
              setvalueamount("");
              setValue(0);
              StackingAllowenceCheck();
              getDepositData();
            }
            setvalueamount("");
            setValue(0);
          } catch (error) {
            if (error.code === -32000) {
              toast.warning("Insufficient Eth For Fee", {
                position: "top-center",
                autoClose: 4000,
              });
              console.log("9999999", typeof error.code);
              throw error;
            } else {
              toast.error(error.message, {
                position: "top-center",
                autoClose: 4000,
              });
              console.log("9999999", error);
              throw error;
            }
          }
        } else {
          if (account) {
            toast.warning("Please Approve Your Coins First", {
              position: "top-center",
              autoClose: 4000,
            });
            if (balanceOf > 0) {
              setMainLoader(true);
              let result = await ApproveTokenAllow();
              setApprov(result);
              if (result) {
                toast.success(
                  "Coins Approved Successfully Click on Confirm Button For Staking",
                  {
                    position: "top-center",
                    autoClose: 4000,
                  }
                );
                let responce = StackingAllowenceCheck();
                // setMainLoader(true);
                if (responce) {
                  setMainLoader(true);
                  const contractAddress = Environment.StackContract;
                  const contract = TomiStackContract(contractAddress, web3);
                  try {
                    // console.log("+++++++++++++++++++++++++++", valueamount)
                    let gasPrice = await web3.eth.getGasPrice();
                    let bal = web3.utils.toWei(valueamount.toString(), "ether");
                    const gas = await contract.methods
                      .stakeForAPY(bal, update)
                      .estimateGas({ from: account, gasPrice });
                    const balance = await contract.methods
                      .stakeForAPY(bal, update)
                      .send({ from: account, gas, gasPrice: gasPrice });
                    if (balance) {
                      setTimeout(() => {
                        getBalance();
                        getDepositData();
                        StackingAllowenceCheck();
                        setMainLoader(false);
                        toast.success("Staked Successfully", {
                          position: "top-center",
                          autoClose: 5000,
                        });
                      }, 10000);
                      setvalueamount("");
                      setValue(0);
                      getDepositData();
                      StackingAllowenceCheck();
                    }
                    setvalueamount("");
                    setValue(0);
                  } catch (error) {
                    if (error.code === -32000) {
                      toast.warning("Insufficient Eth For Fee", {
                        position: "top-center",
                        autoClose: 4000,
                      });
                      console.log("9999999", typeof error.code);
                      throw error;
                    } else {
                      toast.error(error.message, {
                        position: "top-center",
                        autoClose: 4000,
                      });
                      console.log("9999999", error);
                      throw error;
                    }
                  }
                }
              }
            } else {
              toast.error("Tomi balance must be greater then 0", {
                position: "top-center",
                autoClose: 5000,
              });
            }
          } else {
            toast.error("Please connect your wallet first to approve", {
              position: "top-center",
              autoClose: 5000,
            });
          }
        }
      } catch {
        setMainLoader(false);
      }
    } else {
      toast.error("Please Connect Your Wallet First", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    if (account) {
      getBalance();
      StackingAllowenceCheck();
    }
  }, [account]);

  const getDepositData = () => {
    // setMainLoader(true);
    let acc = JSON.stringify(account);
    var data = JSON.stringify({
      query: `query 
          MyQuery {stakedAPYs(
        where: {staker: ${acc}}
        orderBy: stakeTime, 
        orderDirection: desc
      ) {
        lockPeriod
        staker
        amountStaked
        stakesInfo_stakedForAPY
        APY
        index
        stakeTime
        stakesInfo_endTime_
      }}`,
    });
    var config = {
      method: "post",
      url: Environment.TomiactiveStackDeposite,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setActiveDeposit(response?.data?.data?.stakedAPYs);
        // setMainLoader(false);
      })
      .catch(function (error) {
        setMainLoader(false);
      });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
    // console.log("eeeee", e, skip)
    let acc = JSON.stringify(account);
    let skip = JSON.stringify(e.selected);
    var data = JSON.stringify({
      query: `query 
          MyQuery {stakedAPYs(
        where: {staker: ${acc}}
        orderBy: stakeTime, 
        orderDirection: desc
      ) {
        lockPeriod
        staker
        amountStaked
        stakesInfo_stakedForAPY
        APY
        index
        stakeTime
        stakesInfo_endTime_
      }}`,
    });
    var config = {
      method: "post",
      url: Environment.TomiactiveStackDeposite,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log("ressssssssss", total)
        setActiveDeposit(response?.data?.data?.stakedAPYs);
        setPageCount(parseInt(total / limit));
      })
      .catch(function (error) {
        // setMainLoader(false);
      });
  };

  useEffect(() => {
    if (account) {
      getDepositData();
    }
  }, [account]);

  useEffect(() => {
    getTotalStakers();
  }, []);

  const handlePendingreward = async (index) => {
    if (index) {
      try {
        let result = await PendingReward(index);
        setpendreward(result);
      } catch {
        setMainLoader(false);
      }
    }
  };

  const windrawtoken = async (lockp, index) => {
    if (index) {
      try {
        setMainLoader(true);
        // let result = await RewardWidraw(index);
        try {
          if (index) {
            const contractAddress = Environment.StackContract;
            const contract = TomiStackContract(contractAddress, web3);
            let gasPrice = await web3.eth.getGasPrice();
            const gas = await contract.methods
              .unStakeWithAPY(index)
              .estimateGas({ from: account, gasPrice });
            const balance = await contract.methods
              .unStakeWithAPY(index)
              .send({ from: account, gas, gasPrice: gasPrice });
            if (balance && lockp && index) {
              setTimeout(() => {
                handlePendingreward(index);
                getBalance();
                getDepositData();
                handleShowsuccess();
                setMainLoader(false);
              }, 10000);
            }
            // getDepositData()
            // handleShowsuccess()
            getBalance();
          }
        } catch (error) {
          if (error.code === -32000) {
            toast.warning("Insufficient Eth For Fee", {
              position: "top-center",
              autoClose: 4000,
            });
            console.log("9999999", error);
            console.log("9999999", typeof error.code);
            setMainLoader(false);
            throw error;
          } else if (error.code === 4001) {
            toast.error("User Rejected the Transaction", {
              position: "top-center",
              autoClose: 4000,
            });
            setMainLoader(false);
          } else {
            toast.error(
              "Your transaction has been successfully sent. However, due to fluctuations in gas prices, it will take longer than usual to complete. We appreciate your patience. Thank you",
              {
                position: "top-center",
                autoClose: 4000,
              }
            );
            console.log("9999999", error);
            throw error;
          }
        }
      } catch {
        setMainLoader(false);
      }
    }
  };

  const claimfunction = async (index) => {
    if (index) {
      try {
        setMainLoader(true);
        try {
          if (index) {
            const contractAddress = Environment.StackContract;
            const contract = TomiStackContract(contractAddress, web3);
            let gasPrice = await web3.eth.getGasPrice();
            console.log("gassss", gasPrice);
            const gas = await contract.methods
              .claim(index)
              .estimateGas({ from: account, gasPrice });
            const balance = await contract.methods
              .claim(index)
              .send({ from: account, gas, gasPrice: gasPrice });
            if (balance && index) {
              setTimeout(() => {
                handlePendingreward(index);
                getBalance();
              }, 10000);
              handlePendingreward(index);
              getBalance();
              setMainLoader(false);
              toast.success("Claimed Successfully", {
                position: "top-center",
                autoClose: 5000,
              });
            }
          }
        } catch (error) {
          if (error.code === -32000) {
            toast.warning("Insufficient Eth For Fee", {
              position: "top-center",
              autoClose: 4000,
            });
            console.log("9999999", error);
            console.log("9999999", typeof error.code);
            setMainLoader(false);
            throw error;
          } else if (error.code === 4001) {
            toast.error("User Rejected the Transaction", {
              position: "top-center",
              autoClose: 4000,
            });
            setMainLoader(false);
            throw error;
          } else {
            toast.error(
              "Your transaction has been successfully sent. However, due to fluctuations in gas prices, it will take longer than usual to complete. We appreciate your patience. Thank you",
              {
                position: "top-center",
                autoClose: 4000,
              }
            );
            console.log("9999999", error);
            setMainLoader(false);
            throw error;
          }
          // toast.error('Your transaction has been successfully sent. However, due to fluctuations in gas prices, it will take longer than usual to complete. We appreciate your patience. Thank you', {
          //     position: 'top-center',
          //     autoClose: 4000,
          // });
        }
      } catch {
        setMainLoader(false);
      }
    }
  };


  const [stickyClass, setStickyClass] = useState(false);
  const [pay, setPay] = useState(false);
  // const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const [showmobilesidebar, setShowMobileSidebar] = useState(false);
  const handleCloseMobileSidebar = () => setShowMobileSidebar(false);
  const handleShowMobileSidebar = () => setShowMobileSidebar(true);
  const [copied, setCopied] = useState(false);
  const [showconnect, setShowconnect] = useState(false);
  const handleCloseconnect = () => setShowconnect(false);
  const handleShowconnect = () => setShowconnect(true);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      // console.log('Received message:', event.data);
      if (event.data.includes('istomiPAY')) {
        setPay(true)
      }

    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);


  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // console.log("windowscroll", windowHeight);
      windowHeight > 20 ? setStickyClass(true) : setStickyClass(false);
    }
  };


  const Closemodal = () => {
    window.$('#exampleModalwhite44445').modal('hide');
    // history.push('/home')
  };
  const openmodals = () => {
    window.$('#exampleModalwhite44445').modal('show');
  }

  const textCopiedFun = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }


  const trustWallet = async () => {
    console.log("trust")
    localStorage.setItem("flag", "true");
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout('walletconnect');
      localStorage.clear();
    } else {
      login("walletconnect");
      setShowconnect(false)
    }
  };

  const trustWallet1 = async () => {
    console.log("tomi")
    localStorage.setItem("flag", "true");
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout('walletconnect');
      localStorage.clear();
    } else {
      login("walletconnect");
      setShowconnect(false)
    }
  };
  const connectMetaMask1 = () => {
    localStorage.setItem("connectorId", "injected");
    localStorage.setItem("flag", "true");
    if (account) {
      logout();
    } else {
      login("injected");
      setShowconnect(false)
    }
  };

  const logoutwallet = () => {
    const connectorId = window.localStorage.getItem("connectorId")
    logout(connectorId);
    localStorage.removeItem('connectorId')
    // window.location.reload()
  }
  return (
    <>
      {mainLoader && <Loader />}
      <NavbarNew />
      {/* <Navbar /> */}
      <Newbanner />
      <div className="content" style={{ overflow: 'hidden' }}>
        <section className="tokenstaking">
          <div className="custom_container">
            <div className="main-heading text-center">
              <div className="leftheading">
                <h6 className="lefthead">
                  $ {currencyFormatter
                    .format(TPL?.dollar, { code: "USD" })
                    .split(".")[0]
                    .slice(1, 100)}
                </h6>
                <p className="leftpara">TVL</p>
              </div>

              <img src="/assets/Circles.png" alt="img" className="circlesimg img-fluid" />
              <div className="rightheading">
                <p className="righthead">
                  {" "}
                  {currencyFormatter
                    .format(TPL?.tomi, { code: "USD" })
                    .split(".")[0]
                    .slice(1, 100)}
                </p>
                <p className="rightpara">total $TOMI</p>
              </div>
            </div>
            <div className="mainexchanges">
              <h4 className="exchangeshead">
                Find $TOMI on your favorite exchange
              </h4>
              <div className="innerexchanges">
                <a href="https://www.gate.io/trade/TOMI_USDT" target="_blank">
                  <img src="/assets/partnerone.svg" alt="img" className="exchangeimg" />
                </a>
                <a href="https://www.bybit.com/en/trade/spot/TOMI/USDT" target="_blank">
                  <img src="/assets/partnertwo.svg" alt="img" className="exchangeimg" />
                </a>
                <a href="https://trading.bitfinex.com/t/TOMI:USD?type=exchange" target="_blank">
                  <img src="/assets/partnerthree.svg" alt="img" className="exchangeimg" />
                </a>
                <a href="https://www.bitmart.com/trade/en-US?layout=basic&symbol=TOMI_USDT" target="_blank">
                  <img src="/assets/partnerfour.svg" alt="img" className="exchangeimg" />
                </a>
                <a href="https://www.bitget.com/spot/TOMIUSDT" target="_blank">
                <img src="/assets/partnerfive.svg" alt="img" className="exchangeimg" />
                </a>
                <a href="https://www.mexc.com/exchange/TOMI_USDT" target="_blank">
                <img src="/assets/partnersix.svg" alt="img" className="exchangeimg" />
                </a>
                <a href="https://www.htx.com/price/tomi/" target="_blank">
                <img src="/assets/partnerseven.svg" alt="img" className="exchangeimg" />
                </a>
                <a href="https://www.kucoin.com/trade/TOMI-USDT" target="_blank">
                <img src="/assets/partnereight.svg" alt="img" className="exchangeimg" />
                </a>
              <a href="https://www.xt.com/id/trade/tomi_usdt" target="_blank">
                <img src="/assets/partnernine.svg" alt="img" className="exchangeimg" />
                </a>
              </div>
            </div>
            <h4 className="stakemainhead">Stake $TOMI</h4>
            <p className="stakemainpara">Earn rewards by staking TOMI tokens and supporting network security and growth.</p>
            <div className="mainstakecontent">
              <div className="left-content staking-content ">
                <div className="upper-head">
                  <h6>Deposit</h6>
                </div>
                <ButtonGroup
                  className="time-select"
                  aria-label="Basic example"
                >
                  <Button
                    className={update == 0 ? "active-btn" : "yysyvsyvyxs"}
                    onClick={() => setupdate(0)}
                    variant="secondary"
                  >
                    6 Months
                  </Button>
                  <Button
                    className={update == 1 ? "active-btn" : "yysyvsyvyxs"}
                    onClick={() => setupdate(1)}
                    variant="secondary"
                  >
                    1 Year
                  </Button>
                  <Button
                    className={update == 2 ? "active-btn" : "yysyvsyvyxs"}
                    onClick={() => setupdate(2)}
                    variant="secondary"
                  >
                    2 Years
                  </Button>
                </ButtonGroup>
                <div className="option-field">
                  <div className="upper-area">
                    <label>Deposit</label>
                    <h6>
                      Balance:{" "}
                      {balanceOf ? parseInt(balanceOf).toFixed(2) : 0} TOMI
                    </h6>
                  </div>
                  <div className="input-field">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" className="tomiimg">
                      <path d="M12.0003 6.64483C15.2495 6.64483 17.8981 9.19288 18.0204 12.3829L20.8707 14.1171C20.9558 13.6242 21 13.118 21 12.6011C21 7.67623 16.971 3.68384 11.9997 3.68384C7.02831 3.68384 3 7.67686 3 12.6017C3 13.668 3.18887 14.6911 3.53587 15.6382C3.87903 16.5776 4.37713 17.4428 4.99943 18.2052L7.55842 16.648L10.5092 14.8523V21.6838H13.4133V14.7971L16.4473 16.6436L19.0057 18.2008C19.6274 17.4384 20.1248 16.5725 20.4673 15.6325L17.8526 14.041L14.8474 12.2122L12.0067 10.4837L12.0003 10.4875L9.16603 12.2122L6.14992 14.0473C6.03532 13.5881 5.9745 13.1091 5.9745 12.615C5.9745 9.31784 8.67242 6.64546 11.9997 6.64546L12.0003 6.64483ZM12.0003 13.9369L12.0061 13.9401L12.0003 13.9439V13.9363V13.9369Z" fill="white" />
                    </svg>
                    <input
                      type="number"
                      onChange={handleInputChange}
                      value={valueamount}
                      placeholder="Enter TOMI amount"
                    />
                    <button onClick={handleMaxBal}>MAX</button>
                  </div>
                </div>
                <div className="slide-ranger">
                  <Slider
                    value={typeof value === "number" ? value.toFixed(1) : 0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    onChange={handleSliderChange}
                  />
                  <div className="inner-value">
                    <span>0%</span>
                    <span>100%</span>
                  </div>
                </div>
                <div className="bottom-text">
                  <div className="text">
                    <h6>APY</h6>
                    <h5>{apy ? apy : "0"}%</h5>
                  </div>
                  <div className="text">
                    <h6>Total Returns</h6>
                    <h5>
                      {valueamount
                        ? update === 0
                          ? (
                            ((apy / 100) * valueamount) / 2 +
                            parseFloat(valueamount)
                          ).toFixed(6)
                          : update === 1
                            ? (
                              (apy / 100) * valueamount +
                              parseFloat(valueamount)
                            ).toFixed(6)
                            : (
                              (apy / 100) * valueamount * 2 +
                              parseFloat(valueamount)
                            ).toFixed(6)
                        : 0}{" "}
                      <span>
                        TOMI{" "}

                      </span>
                    </h5>
                  </div>
                </div>
                {allowance && allowance > 0 ? (
                  <div className="btn-approve" onClick={StakeFun}>
                    <button>Stake</button>
                  </div>
                ) : (
                  <div className="btn-approve" onClick={ApproveFun}>
                    <button>Approve</button>
                  </div>
                )}
              </div>
              <div className="right-content">
                <div className="upper-head">
                  <h6>Active Deposits</h6>
                </div>
                <div className="gradientborder">


                  <div className="innercontent">
                    <div className="rowsss">
                      {activeDeposit?.length > 0 ? (
                        activeDeposit?.map((elem, index) => {
                          // console.log("elmmmmmmm", elem);
                          let date = new Date(elem?.stakeTime * 1000);
                          const StackIntialDate =
                            moment(date).format("H:mm DD-MM-YYYY");
                          let date1 = new Date(elem?.stakesInfo_endTime_ * 1000);
                          const endTime =
                            moment(date1).format("H:mm:ss DD-MM-YYYY");
                          const currentTime = new Date().getTime();
                          let distandate = currentTime >= date1;
                          // console.log("avlue", distandate)
                          return (
                            <div
                              key={index}
                              className={
                                detailData?.data === "detail" &&
                                  detailData?.id === index
                                  ? "bottom-content1 active-box"
                                  : "bottom-content1"
                              }
                            >
                              <div className="bottom-content ">
                                <div className="itemss">
                                  <h6>Time of deposit</h6>
                                  <h5>{StackIntialDate}</h5>
                                </div>
                                <div className="itemss">
                                  <h6>Lock Period</h6>
                                  {/* <h5>{elem?.lockPeriod == "365" ? "6" : elem?.lockPeriod == "730" ? "12" : elem?.lockPeriod == "1460" ? "24" : ""} Months</h5> */}
                                  <h5>{elem?.lockPeriod} Months</h5>
                                </div>
                                <div className="itemss">
                                  <h6>Deposit Amount</h6>
                                  <h5>
                                    {elem?.amountStaked
                                      ? elem?.amountStaked / 10 ** 18
                                      : 0}{" "}
                                    <span>
                                      TOMI{" "}
                                      <img
                                        src="\assets\tomi-icon.svg"
                                        alt="img"
                                        className="img-fluid ml-2x ml-2"
                                      />
                                    </span>
                                  </h5>
                                </div>
                                <div className="itemss">
                                  <h6>APY</h6>
                                  <h5>
                                    {elem?.lockPeriod == "6"
                                      ? "6%"
                                      : elem?.lockPeriod == "12"
                                        ? "10%"
                                        : elem?.lockPeriod == "24"
                                          ? "12%"
                                          : ""}
                                  </h5>
                                  {/* <h5>{elem?.lockPeriod}%</h5> */}
                                </div>
                                <div className="itemss itemss-sm">
                                  <a
                                    class="hide-btn"
                                    data-toggle="collapse"
                                    href={"#" + index}
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                    onClick={() => {
                                      handlePendingreward(elem?.index);
                                      indexsetfunction(index);
                                      detailData?.data === "detail" &&
                                        detailData?.id === index
                                        ? setDetailData({ data: "", id: "" })
                                        : setDetailData({
                                          data: "detail",
                                          id: index,
                                        });
                                    }}
                                  >
                                    {detailData?.data === "detail" &&
                                      detailData?.id === index ? (
                                      <span>Hide</span>
                                    ) : (
                                      <span>Details</span>
                                    )}{" "}
                                    <img
                                      src="\assets\arrow-down.svg"
                                      alt="img"
                                      className={
                                        detailData?.data === "detail" &&
                                          detailData?.id === index
                                          ? "img-fluid jjbjbbjb ml-2x"
                                          : "img-fluid jjbjbbjbb ml-2x"
                                      }
                                    />
                                  </a>
                                </div>
                              </div>
                              <div
                                className={
                                  addCls === index ? "collapse show" : "collapse"
                                }
                                id={index}
                              >
                                <div className="bottom-content bottom-content2">
                                  <div className="itemss">
                                    <h6>Date of Maturity</h6>
                                    <h5>{endTime}</h5>
                                  </div>
                                  {/* onClick={handleShowsuccess} */}
                                  {/* elem?.stakesInfo_unstaked */}

                                  <div className="itemss">
                                    <h6>Pending Rewards</h6>
                                    <h5>
                                      {pendreward
                                        ? parseFloat(pendreward).toFixed(4)
                                        : 0}{" "}
                                      <span>
                                        TOMI{" "}
                                        <img
                                          src="\assets\tomi-icon.svg"
                                          alt="img"
                                          className="img-fluid ml-2x ml-2"
                                        />
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="itemss">
                                    <div className="endrewards d-flex">
                                      {pendreward > 0 && distandate == false ? (
                                        <button
                                          className="btn-transparent mr-3"
                                          onClick={() =>
                                            claimfunction(elem?.index)
                                          }
                                        >
                                          Claim Rewards
                                        </button>
                                      ) : (
                                        <button className="btn-transparent btn-disable mr-3">
                                          Claim Rewards
                                        </button>
                                      )}
                                      {distandate == true &&
                                        elem?.stakesInfo_stakedForAPY == true ? (
                                        <button
                                          className="btn-pink"
                                          onClick={() =>
                                            windrawtoken(
                                              elem?.stakesInfo_endTime_,
                                              elem?.index
                                            )
                                          }
                                        >
                                          {" "}
                                          Redeem
                                        </button>
                                      ) : (
                                        <button className="btn-pink btn-disable">
                                          Redeem
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="sdhgavschjBcasjkbf">
                          <h4>
                            Here your active stakings will appear and you’ll be able to claim your rewards
                          </h4>

                          {account ?
                            (
                              <button className="connectbtn" onClick={logoutwallet}>disconnect Wallet</button>
                            )
                            :
                            (
                              <button className="connectbtn" onClick={handleShowconnect}>Connect Wallet</button>
                            )
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="pagination-custom">
                                    {pageCount >= 1 ?
                                        <div className="text-center">
                                            <ReactPaginate
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                forcePage={page}
                                            ></ReactPaginate>
                                        </div>
                                        : ''}
                                </div> */}
            </div>
          </div>
        </section>
        <Power />
      </div>


      <Modal
        className="bidmodal"
        show={showsuccess}
        onHide={handleClosesuccess}
        centered
      >
        <Modal.Body>
          <div className="modalhead">
            <h4 className="modalheading">Reward Claimed</h4>
            <button className="btnclose" onClick={handleClosesuccess}>
              <img
                src="\assets\close-icon.svg"
                alt="cross"
                className="cross_btns"
              />
            </button>
          </div>
          <div className="loadermain">
            <img src="\assets\tick.svg" alt="success" className="tick" />
            <p className="loadertext">Check your wallet for your rewards</p>
          </div>
          <button className="common-btn" onClick={handleClosesuccess}>
            Done
          </button>
        </Modal.Body>
      </Modal>

      <Modal className="connectmodal" show={showconnect} onHide={handleCloseconnect} centered>
        <Modal.Body>
          <div className="modalhead">
            <h4 className="modalheading">Connect Wallet</h4>
            <button className="btnclose" onClick={handleCloseconnect}><img src="\assets\close-icon.svg" alt="cross" className="cross_btns" /></button>
          </div>
          <div className="connectwalletdiv">
            <div className="wallet MOBIKEDEISGNSS" onClick={connectMetaMask1}>
              <img src="\assets\metamask.svg" alt="logo" className="walletimg" />
              <p className="walletname">Metamask</p>
            </div>
            {/* {width < 768 ? */}
            {/* (
                <OpenApp href="https://metamask.app.link/dapp/staking.tomi.com/">
                  <div className="wallet">
                    <img src="\assets\metamask.svg" alt="logo" className="walletimg" />
                    <p className="walletname">Metamask</p>
                  </div>
                </OpenApp>
              )
              :
              (
                <div className="wallet" onClick={connectMetaMask1}>
                  <img src="\assets\metamask.svg" alt="logo" className="walletimg" />
                  <p className="walletname">Metamask</p>
                </div>
              ) */}
            {/* } */}
            <div className="wallet" onClick={trustWallet}>
              <img src="\assets\walletconnect.svg" alt="logo" className="walletimg" />
              <p className="walletname">WalletConnect</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Token_staking;
