let Environment = {
  TomiContract: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  StackContract: "0xEB3c2E183660D52B76f386ac5BD91dB3A4B3ED63",
  TomiactiveStackDeposite: "https://api.studio.thegraph.com/query/41265/stakingmainnet/0.11"

  // testnet 
  // TomiContract: "0xbd7465f82b0A332B61480F6F89dB950a7e307910",
  // StackContract: "0x3b1F00b27C21bA2961f9a83BA952Abb9AbC496c7",
  // TomiactiveStackDeposite: "https://api.studio.thegraph.com/query/41265/tomistakes/0.2.2"
};

export default Environment;
