
import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3"
import { getTomiContract } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";


export const TomiAllowance =()=>{
    const {account} = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.TomiContract;
    const contract = getTomiContract(contractAddress,web3);
    const AllowanceTomi = useCallback (
        async() =>{
            // let val = web3.utils.toWei(spendBnb.toString(), "ether");
            try {
                const balance = await contract.methods.allowance(account,Environment.StackContract).call()
                let bal = web3.utils.fromWei(balance, "ether")
                return bal;
            } catch (error) {
                console.log('9999999', error)
                throw error;
            }
        },[contract]
    );
    return { AllowanceTomi: AllowanceTomi };
}
export default TomiAllowance;