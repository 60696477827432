import React from 'react'
import './power.scss'

const Power = () => {
  return (
    <>
      <section className="mainpower">
        <img src="/assets/Hotbg.png" alt="mainpowerbg" className="mainpowerbg" />
        <span className='powerleftshade'></span>
        <span className="powerrightshade"></span>
        <div className="custom_container">
        <h4 className="powerhead">Power Your tomi Experience with <span className="pinkclr">TOMI Token</span></h4>
        <p className="powerpara">
        Unlock exclusive features, rewards, and governance rights within the tomi ecosystem. Buy TOMI on top exchanges (Bybit, Gate.io,...) & stake for additional benefits. Join the tomi revolution!
        </p>
        </div>
      </section>
    </>
  )
}

export default Power
