import React from "react";
import "./newbanner.scss";

const Newbanner = () => {
  return (
    <>
      <section className="newbanner">
        <img src="/assets/bannerbg.png" alt="img" className="bannerbgimg" />
        <div className="custom-container">
          <p className="explorepara">
            <span className="greyclr">Explore //</span>
            <span className="pinkclr">tomi Token</span>
          </p>
          <h4 className="unlockhead">
            Unlock <span className="pinkclr">The Full Power</span> of tomi
          </h4>
          <p className="usepara">
            Use TOMI to pay for tomi services, like storage, VPN, and more!{" "}
          </p>
          {/* <button className="pinkbtn">
            Get Early Access
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M10 5.92969L8.5 7.42969L13.0703 12L8.5 16.5703L10 18.0703L16.0703 12L10 5.92969Z"
                fill="white"
              />
            </svg>
          </button> */}
        </div>
      </section>
    </>
  );
};

export default Newbanner;
